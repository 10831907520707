import _objectSpread from "D:/fsm/acro-harvest/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import Tabledata from "@/view/pages/follow-up/Tabledata.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
export default {
  name: "Follow-ups",
  mixins: [CommonMixin],
  data: function data() {
    return {
      //tab:null,
      //items: [10, 25, 50, 75, 100, ],
      routeAPI: "followup",
      routeName: "followup",
      routeDetailName: "followup.detail",
      status: "all",
      pageModule: "followup-listing",
      dataLoading: false,
      searchDialog: false,
      searchDialogtable: false,
      statusList: [{
        id: 5,
        type: 1,
        text: "All Follow-Up",
        value: "all",
        description: "All Follow-Up",
        activity_text: "All Follow-Up",
        color: "cyan",
        textcolor: "white",
        headercolor: "primary",
        order: 1,
        status: 0,
        top_visible: true,
        activated: true,
        added_by: 1,
        added_at: "2022-05-10 08:32:37",
        updated_by: 0,
        updated_at: null,
        customer_status_count: 0,
        all_customers: 0
      }, {
        id: 5,
        type: 1,
        text: "Open",
        value: "all",
        description: "Open",
        activity_text: "Open",
        color: "cyan",
        textcolor: "white",
        headercolor: "primary",
        order: 1,
        status: 0,
        top_visible: true,
        activated: true,
        added_by: 1,
        added_at: "2022-05-10 08:32:37",
        updated_by: 0,
        updated_at: null,
        customer_status_count: 0,
        all_customers: 0
      }, {
        id: 5,
        type: 1,
        text: "Closed",
        value: "all",
        description: "Closed",
        activity_text: "Closed",
        color: "cyan",
        textcolor: "white",
        headercolor: "primary",
        order: 1,
        status: 0,
        top_visible: true,
        activated: true,
        added_by: 1,
        added_at: "2022-05-10 08:32:37",
        updated_by: 0,
        updated_at: null,
        customer_status_count: 0,
        all_customers: 0
      }]
    };
  },
  components: {
    PageHeaderCount: PageHeaderCount,
    ListingTemplate: ListingTemplate,
    // ListingSearchCriteria,
    Tabledata: Tabledata,
    Dialog: Dialog
  },
  created: function created() {
    var _this = this;

    if (_this.$route.query && _this.$route.query.tab) {
      _this.followupTab = _this.$route.query.tab;
    }
  },
  methods: {
    updateStatusList: function updateStatusList(param) {
      this.statusList = param;
    },
    getProfileImage: function getProfileImage(profile_logo) {
      if (profile_logo && profile_logo.file && profile_logo.file.url) {
        return profile_logo.file.url;
      }

      return null;
    },
    getBillingAddress: function getBillingAddress(billing) {
      var billingArray = new Array();

      if (this.lodash.isEmpty(billing) === false) {
        if (billing.unit_no) {
          billingArray.push(billing.unit_no);
        }

        if (billing.street_1) {
          billingArray.push(billing.street_1);
        }

        if (billing.street_2) {
          billingArray.push(billing.street_2);
        }

        if (billing.zip_code) {
          billingArray.push(billing.zip_code);
        }
      }

      return billingArray.join(", ");
    },
    moreAction: function moreAction(action) {
      var _this = this;

      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;

        case "export_items":
          _this.bulkCustomerExport();

          break;

        case "export_current_view":
          _this.exportCurrentView("customer");

          break;

        case "refresh_list":
          _this.getRows();

          break;
      }
    },
    updateMoreAction: function updateMoreAction(param) {
      var _this = this;

      switch (param) {
        case "active":
          _this.bulkCustomerUpdate({
            customers: _this.selectedRows,
            status: 1
          });

          break;

        case "inactive":
          _this.bulkCustomerUpdate({
            customers: _this.selectedRows,
            status: 0
          });

          break;
      }
    }
  },
  computed: {
    followupTab: {
      set: function set(val) {
        var query = _objectSpread({}, this.$route.query);

        query.tab = val;

        if (val != this.followupTab) {
          this.$router.push({
            query: query
          });
        }
      },
      get: function get() {
        return this.$route.query.tab || "overview";
      }
    }
  }
};